<template>
    <main-template>
        <template v-slot:headerLottieDark>
            <dotlottie-player :src="getDarkLogo()"
                              background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
        </template>
        <template v-slot:headerLottieLight>
            <dotlottie-player :src="getLightLogo()"
                              background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
        </template>
        <template v-slot:header>Base</template>
        <template v-slot="slotProps">
            <fetch-siren-root :linkName='linkName' :link="link" @responseLoaded="loadUsers">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                    <div class="flex w-full overflow-hidden">
                        <div class="p-2 h-full">
                            <Hr-navigation :links="slotProps.links"/>
                        </div>
                        <div class="flex w-full h-full p-2 overflow-hidden">
                            <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden" :class="{'grid grid-cols-3': (userDetails || loadingDetails) && !hideTable}">
                                <loading v-if="!response"></loading>
                                <template v-else>
<!--                                    <div class="p-2 overflow-y-auto h-full" :class="{'hidden': hideTable}">-->
                                    <blank-header>
                                        <template v-slot:title>Onboarding</template>
                                    </blank-header>
                                        <HrPeoplePendingPagination ref="peopleTable" :users="users" :response="userResponse">
                                            <template v-slot:additional-buttons>
<!--                                                <form-action-->
<!--                                                    v-if="userResponse && userResponse.body.actions.filter(action => action.name === 'add-new-user').first()"-->
<!--                                                    :action="userResponse.body.actions.filter(action => action.name === 'add-new-user').first()"-->
<!--                                                    :redirect-on-success="false"-->
<!--                                                    @success="(res)=>{ loadUsers(slotProps); userCreated(res)}"-->
<!--                                                ></form-action>-->
                                            </template>
                                        </HrPeoplePendingPagination>
<!--                                    </div>-->
<!--                                    <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2 col-span-2 overflow-hidden" v-if="userDetails || loadingDetails">-->
<!--                                        <hr-person-single ref="singleDetails" v-if="userDetails" :userDetails="userDetails" :editing="editing" :update-action="updateAction" @saveClicked="saveClicked" :hideTable="hideTable" @hideTable="toggleHideTable" page="hr-people"></hr-person-single>-->
<!--                                        <loading v-else class="sticky top-0"></loading>-->
<!--                                    </div>-->
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import FetchSirenRoot from "@/components/FetchSirenRoot";
import MainTemplate from "@/v3/templates/MainTemplate.vue";
import Loading from "@/components/Loading";
import Button from "@/v2/buttons/Button";
import PageHeader from "@/v2/components/Header";
import FormAction from "@/v3/components/forms/FormAction.vue";
import ClientUserPaginatedList from "@/v2/paginatedLists/ClientUsersPaginatedList";
import AddClientUserForm from "@/v2/components/forms/AddClientUserForm";
import ClientPendingUserPaginatedList from "@/v2/paginatedLists/ClientPendingUsersPaginatedList.vue";
import HrPeoplePagination from "@/v3/components/pagination/HrPeoplePagination.vue";
import HrPersonSingle from "@/v3/components/HrPersonSingle.vue";
import HrNavigation from "@/v3/components/navigation/HrNavigation.vue";
import Siren from "super-siren";
import HrPeoplePendingPagination from "@/v3/components/pagination/HrPeoplePendingPagination.vue";
import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue";
import BlankHeader from "@/v3/components/headers/BlankHeader.vue";
export default {
    name: "ClientPendingUsers",
    data() {
        return {
            loading: false,
            users: null,
            userResponse: null
        }
    },
    components: {
        BlankHeader,
        CollapsableHeader,
        HrPeoplePendingPagination,
        HrNavigation, HrPersonSingle, HrPeoplePagination,
        ClientPendingUserPaginatedList,
        AddClientUserForm,
        ClientUserPaginatedList,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        FormAction,
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        loadUsers(response){
            this.users = response.body.entities;
            this.userResponse = response;
        },
        showUserDetails(userDetails){
            this.tabOpen= 'basics';
            this.editing = false;
            this.userDetails = userDetails;
            this.loadingDetails = false;
        },
        setLoading(){
            this.userDetails = null;
            this.loadingDetails = true;
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_light"
        },
    }
}
</script>

<style scoped>

</style>
